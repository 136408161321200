import Vuex from 'vuex';
import { createLocalVue, shallowMount } from '@vue/test-utils';
import AdyenCheckout from '@adyen-web-3.23.0';
import { nameSpace as AdyenStoreHubNameSpace } from '../stores/adyenStore';

import ProviderAddPaymentMethod from './ProviderAddPaymentMethod';

jest.mock('@adyen-web-3.23.0/dist/adyen.css', () => { });

jest.mock('@/config', () => ({
  data: {
    adyenEnvironment: 'adyenEnvironment',
  },
}));

const AdyenStoreModule = {
  namespaced: true,
  state: {
    originKey: 'origin-key',
  },
  actions: {
    loadOriginKey: jest.fn().mockResolvedValue('origin-key'),
  },
};

const mockedModules = {
  [AdyenStoreHubNameSpace]: AdyenStoreModule,
};

const localVue = createLocalVue();
localVue.use(Vuex);

const store = new Vuex.Store({
  modules: mockedModules,
});

let propsData = {
  userUuid: 'test-uuid',
  onlyCvc: false,
};

let wrapper;

const createWrapper = () => shallowMount(ProviderAddPaymentMethod, {
  localVue,
  store,
  propsData,
});

describe('Given ProviderAddPaymentMethod component', () => {
  describe('When the "created" hook is called', () => {
    describe('And onlyCvc is disabled', () => {
      beforeEach(() => {
        wrapper = createWrapper();
      });
      it('Then the "loadOriginKey" method is called', () => {
        const loadOriginKeySpy = jest.spyOn(ProviderAddPaymentMethod.methods, 'loadOriginKey');
        wrapper = createWrapper();

        expect(loadOriginKeySpy).toHaveBeenCalled();
      });

      it('Then the "createCardComponent" method is called', async () => {
        const createCardComponentSpy = jest.spyOn(ProviderAddPaymentMethod.methods, 'createCardComponent');
        wrapper = createWrapper();

        await wrapper.vm.$nextTick();

        expect(createCardComponentSpy).toHaveBeenCalled();
      });

      it('Then the "on:ready" event is emitted', async () => {
        wrapper = createWrapper();

        await wrapper.vm.$nextTick();

        expect(wrapper.emitted('on:ready')[0]).toBeTruthy();
      });
    });

    describe('And onlyCvc is enabled', () => {
      beforeEach(() => {
        propsData = {
          userUuid: 'test-uuid',
          onlyCvc: true,
          cvcConfig: {
            type: 'visa',
            expiry: '2/2023',
            holder: 'pep',
            reference: 'ref',
            number: '4444',
          },
        };
      });

      afterEach(() => {
        jest.clearAllMocks();
        jest.resetModules();
      });

      it('Then the "loadOriginKey" method is called', () => {
        const loadOriginKeySpy = jest.spyOn(ProviderAddPaymentMethod.methods, 'loadOriginKey');
        wrapper = createWrapper();

        expect(loadOriginKeySpy).toHaveBeenCalled();
      });

      it('Then the "createCardComponent" method is called', async () => {
        const createCVCComponentSpy = jest.spyOn(ProviderAddPaymentMethod.methods, 'createCVCComponent');
        wrapper = createWrapper();

        await wrapper.vm.$nextTick();

        expect(createCVCComponentSpy).toHaveBeenCalled();
      });

      it('Then the "on:cvc-ready" event is emitted', async () => {
        wrapper = createWrapper();

        await wrapper.vm.$nextTick();

        expect(wrapper.emitted('on:cvc-ready')[0]).toBeTruthy();
      });
    });
  });

  describe('When the "createCardComponent" method is called', () => {
    beforeEach(() => {
      jest.resetModules();
      wrapper = createWrapper();
    });

    afterEach(() => {
      jest.clearAllMocks();
      jest.resetModules();
    });

    it('Then the "buildCheckoutConfiguration" method is called', () => {
      const buildCheckoutConfigurationSpy = jest.spyOn(wrapper.vm, 'buildCheckoutConfiguration');

      wrapper.vm.createCardComponent();

      expect(buildCheckoutConfigurationSpy).toHaveBeenCalled();
    });

    it('Then the "buildCardConfiguration" method is called', () => {
      const buildCardConfigurationSpy = jest.spyOn(wrapper.vm, 'buildCardConfiguration');

      wrapper.vm.createCardComponent();

      expect(buildCardConfigurationSpy).toHaveBeenCalled();
    });

    it('Then the "checkout.create" method is called', () => {
      const checkoutMock = jest
        .spyOn(AdyenCheckout.prototype, 'create')
        .mockImplementation(() => 'card');

      wrapper.vm.createCardComponent();

      const cardConfig = {
        hasHolderName: true,
        holderNameRequired: true,
        placeholders: {
          encryptedCardNumber: '4111 1111 1111 1111',
          encryptedExpiryDate: '03/30',
          encryptedSecurityCode: '737',
        },
      };

      expect(checkoutMock).toHaveBeenCalledWith('card', cardConfig);
    });
  });
});
