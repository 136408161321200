// Adyen/3.23.0

<template>
  <div :class="['payment-method-main', {'pt-3': onlyCvc}]">
    <div
      v-if="!onlyCvc"
      id="add-card"
      ref="addCard"
      class="adyen-provider-main"
    />

    <div
      v-if="onlyCvc"
      id="cvc-card"
      ref="cvcCard"
    />
  </div>
</template>

<script>
import AdyenCheckout from '@adyen-web-3.23.0';
import '@adyen-web-3.23.0/dist/adyen.css';

import { mapActions, mapState } from 'vuex';
import { adyenEnvironment } from './utils';
import { nameSpace as AdyenStoreHubNameSpace } from '../stores/adyenStore';

import {
  DEFAULT_LOCALE, DEFAULT_PLACEHOLDERS, MASTERCARD_TYPE, PAYMENT_METHODS_CONFIG,
} from '../constants/adyen.const';

const environment = adyenEnvironment();

export default {
  name: 'ProviderAddPaymentMethod',

  props: {
    userUuid: {
      type: String,
      required: true,
    },

    locale: {
      type: String,
      default: DEFAULT_LOCALE,
    },

    onlyCvc: {
      type: Boolean,
      default: false,
    },

    cvcConfig: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      currentCvcUuid: null,
    };
  },

  computed: {
    ...mapState(AdyenStoreHubNameSpace, ['originKey']),
  },

  watch: {
    cvcConfig(value) {
      if (value.userUuid !== this.currentCvcUuid) {
        this.card.unmount();
        this.card = this.createCVCComponent();
        this.card.mount(this.$refs.cvcCard);
        this.$emit('on:cvc-ready');
        this.currentCvcUuid = value.userUuid;
      }
    },
  },

  async created() {
    await this.loadOriginKey(this.userUuid);

    if (!this.onlyCvc) {
      this.card = this.createCardComponent();
      this.card.mount(this.$refs.addCard);
      this.$emit('on:ready');
    } else {
      this.card = this.createCVCComponent();
      this.card.mount(this.$refs.cvcCard);
      this.$emit('on:cvc-ready');

      this.currentCvcUuid = this.cvcConfig.userUuid;
    }
  },

  methods: {
    ...mapActions(AdyenStoreHubNameSpace, ['loadOriginKey']),

    buildCheckoutConfiguration() {
      return {
        environment,
        locale: this.locale || DEFAULT_LOCALE,
        onChange: state => {
          const candidatePaymentMethod = {
            isValid: state.isValid,
            paymentMethod: state.isValid ? state.data.paymentMethod : null,
          };

          this.$emit('update:provider-candidate-payment-method', candidatePaymentMethod);
        },

        onError: errorMsg => {
          this.$emit('on:error', errorMsg.errorI18n);
        },
        originKey: this.originKey,
        paymentMethodsResponse: PAYMENT_METHODS_CONFIG,
      };
    },

    buildCardConfiguration() {
      return {
        hasHolderName: true,
        holderNameRequired: true,
        placeholders: DEFAULT_PLACEHOLDERS,
      };
    },

    createCardComponent() {
      const checkoutConfiguration = this.buildCheckoutConfiguration();
      const checkout = new AdyenCheckout(checkoutConfiguration);

      const cardConfiguration = this.buildCardConfiguration();
      return checkout.create('card', cardConfiguration);
    },

    createCVCComponent() {
      const commonStoredPaymentMethods = [{
        brand: this.cvcConfig.type === MASTERCARD_TYPE.long ? MASTERCARD_TYPE.short : this.cvcConfig.type,
        expiryMonth: this.cvcConfig.expiry.split('/')[0],
        expiryYear: `20${this.cvcConfig.expiry.split('/')[1]}`,
        holderName: this.cvcConfig.holder,
        id: this.cvcConfig.reference,
        lastFour: this.cvcConfig.number,
        name: this.cvcConfig.type.toUpperCase(),
        supportedShopperInteractions: ['ContAuth'], // Ecommerce, ContAuth
        type: 'scheme',
      }];

      const CVCCheckoutConfiguration = {
        environment,
        locale: this.locale || DEFAULT_LOCALE,
        onChange: state => {
          const CVCCandidatePaymentMethod = {
            isValid: state.isValid,
            paymentMethod: {
              ...state.isValid ? state.data.paymentMethod : null,
              ...this.cvcConfig,
            },
          };

          this.$emit('update:cvc-candidate-payment-method', CVCCandidatePaymentMethod);
        },

        onError: errorMsg => {
          this.$emit('on:error', errorMsg.errorI18n);
        },
        originKey: this.originKey,

        paymentMethodsResponse: {
          ...PAYMENT_METHODS_CONFIG,
          storedPaymentMethods: commonStoredPaymentMethods,
        },
      };

      const CVCCardConfiguration = {
        hasHolderName: true,
        holderName: this.cvcConfig.holder,
        type: this.cvcConfig.type === MASTERCARD_TYPE.long ? MASTERCARD_TYPE.short : this.cvcConfig.type,

        expiryMonth: this.cvcConfig.expiry.split('/')[0],
        expiryYear: `20${this.cvcConfig.expiry.split('/')[1]}`,

        storedPaymentMethodId: this.cvcConfig.number,
      };

      const CVCCheckout = new AdyenCheckout(CVCCheckoutConfiguration);
      return CVCCheckout.create('card', CVCCardConfiguration);
    },
  },
};
</script>
